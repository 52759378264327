import style from "./flag.module.scss";
import React from "react";

interface FlagProps {
  backgroundColor: string;
  position: 'floating' | 'fixed';
  status: 'active' | 'maintenance' | 'pending' | 'disabled';
}

export const FlagComponent: React.FunctionComponent<FlagProps> = (props) => {
  const { backgroundColor, position, status } = props;

  const myAfterStyle = position === "fixed" ? {
    content: ' ',
    position: 'absolute',
    marginTop: '13.5px',
    borderBottom: `7px solid ${backgroundColor}`,
    borderLeft: '7.5px solid transparent',
    borderRight: '7.5px solid transparent'
  } : {
    content: ' ',
    position: 'absolute',
    marginLeft: '200px',
    marginTop: '20.5px',
    borderBottom: `7px solid ${backgroundColor}`,
    borderLeft: '13px solid transparent',
    borderRight: '12px solid transparent'
  }

  return (
    <React.Fragment>
      <div className={`${style.flag} ${style[`flag_${status}`]} ${position === "fixed" ? style.flag_fixed : style.flag_floating}`}/>
      <div style={myAfterStyle}></div>
    </React.Fragment>
  )
}