import React, { useEffect, useState } from "react";
import styles from './Content.module.scss';
import { AccessBox } from "./AccessBox/AccessBox";
import * as _ from "lodash";
import { useToasts } from "react-toast-notifications";
import {
  Accesses,
  AccessListResponse,
  AccessPurposes,
  LockTypes
} from "@common/typing";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import { FlagComponent } from "./../FlagComponent/FlagComponent";
import { MainComponent } from "./../MainComponent/MainComponent";
import { AdminManagerService } from "../../services/admin-manager.service"
import { NotesComponent } from "./NotesComponent/NotesComponent";

interface ContentProps {}

export const Content: React.FunctionComponent<ContentProps> = (_props) => {
  const [isLoading, setIsLoading] = useState(false);
  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const adminManager = new AdminManagerService();
  const [unitAccesses, setUnitAccesses] = useState([]);
  const [lockTypes, setLockTypes] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const { addToast } = useToasts();
  const flagStatuses = [
    { status: "active", display: 'Active' },
    { status: "pending", display: "Pending Validate" },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (+adminManager.getSuccess()) {
          addToast("Saved Access", {appearance: "success"});
        }
        await loadUnitAccess();
      } catch (e) {
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let height = document.getElementById('root')?.clientHeight;
    window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
  } );

  const loadUnitAccess = async () => {
    let sortedUnitAccess: Array<Accesses>;
    let includedLockTypes: Array<LockTypes>;
    let includedPurposes: Array<AccessPurposes>;
    try {
      const unitAccessTypeResponse: AccessListResponse = await unitService.getUnitAccesses(info.unitId);
      if (!_.isNil(unitAccessTypeResponse.data)) {
        sortedUnitAccess = unitAccessTypeResponse.data.sort((a, b) => {
          // Only sort on purpose_id if not identical
          if (a.attributes.purpose_id < b.attributes.purpose_id) return -1;
          if (a.attributes.purpose_id > b.attributes.purpose_id) return 1;
          // Sort on access_sequence.
          if (a.attributes.access_sequence < b.attributes.access_sequence) return -1;
          if (a.attributes.access_sequence > b.attributes.access_sequence) return 1;
          // Both identical, return 0
          return 0;
      });
        setUnitAccesses(sortedUnitAccess);
      }

      if (!_.isNil(unitAccessTypeResponse.included)) {
        includedLockTypes = unitAccessTypeResponse.included.filter((item) => {
          if (item.type === "lock_type")
            return item.attributes;
        });
        setLockTypes(includedLockTypes);

        includedPurposes = unitAccessTypeResponse.included.filter((item) => {
          if (item.type === "access_purpose")
            return item.attributes;
        });
        setPurposes(includedPurposes);
      }
    } catch (e) {
      addToast(`Error loading Access Type information`, { appearance: "error" });
      setIsLoading(false);
    }
  };

  const accessStatus = (access) => {
    if (!access.validated) return "pending";
    else  return "active";
  }

  const handleOpenCreationModal = () => {
    window.parent.postMessage({action: 'click', type: 'showAccessModal'}, '*');
  };

  return (
    <div className={`${styles.summaryApp}`}>
      <div className={`container-fluid ${styles.Container}`}>
        <div className={styles.titleContainer}></div>
        <div className={styles.optionsContainer}>
          <b><a href={"#"} onClick={() => handleOpenCreationModal()} className={styles.optionLink}>+ Add Access</a></b>
        </div>
        {isLoading ? (
          <div className="row">
            <div className={`col ${styles.centerContent}`}>
              <h6>Loading data...</h6>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className={`${styles.ContainerAccessList}`}>
              <div id="access-list-container" className={`${styles.AccessList}`}>
                {unitAccesses.map((unitAccess: Accesses, index) => {
                  const unitAccessLockType = lockTypes.find((lt) => lt.id === unitAccess.attributes.lock_type_id);
                  const unitAccessPurpose = purposes.find((p) => p.id === unitAccess.attributes.purpose_id);
                  return (
                    <AccessBox
                      lockType={unitAccessLockType?.attributes.display_name}
                      content={{
                        id: +unitAccess.id,
                        name: unitAccess.attributes.access_name,
                        location: unitAccess.attributes.location ?? "--",
                        purpose: unitAccessPurpose?.attributes?.display_name ?? "--",
                        status: accessStatus(unitAccess.attributes),
                        main: unitAccess.attributes.is_main
                      }}
                    >
                    </AccessBox>
                  )
                })}
              </div>     
              <div className={`content-footer ${styles.footer}`}>
                {flagStatuses.map(flagStatus => {
                  return (<div className={styles.infographic}>
                    <FlagComponent backgroundColor={'#e5ebed'} position={'fixed'} status={flagStatus.status}/>
                    <p>{flagStatus.display}</p>
                  </div>)
                })}
                <div className={styles.infographic}>
                  <MainComponent position={'fixed'}/>
                  <p>Main access</p>
                </div>
              </div>
              <div className={`${styles.ContainerNotes}`}>
                <NotesComponent/>
              </div>
            </div>
          </React.Fragment>
        )}

      </div>
    </div>
  );
};