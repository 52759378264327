import React from "react";
import styles from './AccessBox.module.scss';
import { ThemeProvider, Tooltip, Icon } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import { FlagComponent } from "../../FlagComponent/FlagComponent";
import { MainComponent } from "../../MainComponent/MainComponent";

interface AccessBoxProps {
  lockType: string;
  content: {id: number; name: string; location: string; purpose:string, status: string, main: boolean};
  children?: JSX.Element | never[];
}

export const AccessBox: React.FunctionComponent<AccessBoxProps> = (props) => {
  const {lockType, content, children} = props;
  const maxLengthLocationField = 32;
  const maxLengthNameField = 20;

  const handleOpenModal = (id: number) => {
    window.parent.postMessage({action: 'click', type: 'showAccessModal', id: id}, '*');
  };

  return (
    <ThemeProvider theme={_default}>
      <div className={styles.infobox}>
        <div className={styles.header}>
          <span className={styles.label}><b>{lockType}</b></span>
        </div>

        {content.main ? (<MainComponent position={"floating"} />) : (<React.Fragment/>)}
        <FlagComponent backgroundColor={'#C0C2C3'} position={"floating"} status={content.status}/>

        {children ? (
          <div className={`${styles.imageContent} vertical`}>{children}</div>
        ) : (<React.Fragment/>)
        }

        <div className={styles.content}>
          <div className={"row"}>
            <div className={`col-sm-8 ${styles.title}`}>
              {content.name?.length > maxLengthNameField ? (
                <span>{content.name?.substr(0, maxLengthNameField)}...</span>
              ) : (
                <span>{content.name}</span>
              )}
            </div>
            <div className={`col-sm-4 ${styles.content_tools}`}>
              <Tooltip message={"Edit"} placement={"bottom"}>
                <button type="button" className={`${styles.icon} ${styles.notButton}`} onClick={() => handleOpenModal(content.id)}>
                  <Icon.Edit className="pointer" name="info" width={18} height={18} />
                </button>
              </Tooltip>
            </div>
          </div>

          <div className={"row"}>
            <div className={`col-sm-12 ${styles.subtitle}`}>
              {content.location.length > maxLengthLocationField ? (
                <Tooltip message={content.location} placement={"bottom"}>
                  <p className={styles.subtitleText}>{content.location.substr(0, maxLengthLocationField)}...</p>
                </Tooltip>
              ) : (
                <p className={styles.subtitleText}>{content.location}</p>
              )}
            </div>
          </div>
        </div>

        <div className={styles.background}>
          <span> {content.purpose} </span>
        </div>
      </div>
    </ThemeProvider>
  );
};