import React, {useState, useEffect} from "react";
import styles from "./AccessDetailsComponent.module.scss";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import {
  Accesses,
  AccessListResponse,
  AccessPurposes,
  AccessRetrieveResponse,
  AccessTypes,
  LockModel,
  LockTypes,
  MakeListResponse,
  ModelListResponse,
  UnitAccessConfiguration
} from "@common/typing";
import { AdminManagerService } from "../../services/admin-manager.service";
import _ from "lodash";
import {
  ButtonGroup,
  Input,
  Select,
  Required,
  ThemeProvider,
  Button,
  AlertMessage,
  Icon,
  Tooltip
} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import {TextareaComponent} from "@common/react-components";
import { useToasts } from "react-toast-notifications";
import { FormControlLabel, Modal } from "@material-ui/core";
import { LockConfigurationsComponent } from "./configurationComponents/LockConfigurationsComponent";
import * as Shared from "./sharedComponents";
import AccessDetailStyles from "./AccessDetailsComponent.module.scss";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface AccessDetailsProps {}

export const AccessDetailsComponent: React.FunctionComponent<AccessDetailsProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [access, setAccess] = useState<Accesses>({} as Accesses);
  const [accessTypes, setAccessTypes] = useState<Array<{value: number, display: string}>>([]);
  const [accessTypeId, setAccessTypeId] = useState<number>(1); //we set it in 1 because now we only need Lock
  const [lockTypes, setLockTypes] = useState<Array<{value: number, display: string}>>([]);
  const [purposes, setPurposes] = useState<Array<{value: number, display: string}>>([]);
  const [sequences, setSequences] = useState<Array<{value: number, display: string}>>([]);
  const [models, setModels] = useState<Array<{id: number, label: string}>>([]);
  const [allModels, setAllModels] = useState<Array<LockModel>>([]);
  const [makes, setMakes] = useState<Array<{id: number, label: string}>>([]);
  const [currentModel, setCurrentModel] = useState<{id: number | null, label: string}>({id: null, label: ""});
  const [currentMake, setCurrentMake] = useState<{id: number | null, label: string}>({id: null, label: ""});
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const [protectedConfigValues, setProtectedConfigValues] = useState<object>({ instructions: true });
  const [configurations, setConfigurations] = useState<Array<UnitAccessConfiguration>>([]);
  const [selectState, setSelectState] = useState<{}>({
    purpose: null,
    sequence: null
  });
  const [inputState, setInputState] = useState({
    access_name: null,
    location: null,
    lock_model: null,
    lock_maker: null
  });
  const [requiredFields, setRequiredFields] = useState<Array<{fieldName: string, displayName: string}>>([]);
  const [requiredConfigs, setRequiredConfigs] = useState<Array<{fieldName: string, displayName: string, typeId: number}>>([]);
  const [isCreation, setIsCreation] = useState<boolean>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [notSpecifiedMakeSuggestion, setNotSpecifiedMakeSuggestion] = useState<boolean>(false);
  const [mainAccess, setMainAccess] = useState<Accesses>({} as Accesses);

  const adminManager = new AdminManagerService();
  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const { addToast } = useToasts();
  const openText = "Read More »";
  const collapseText = "read less";
  const maxLength = 1000;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const accessId = adminManager.getId();
        await getSelectValues();
        const allModelsResponse = await unitService.getModelsByLockType(accessTypeId);
        setAllModels(allModelsResponse.data);
        setRequiredFields([
          { fieldName: "lock_type_id", displayName: 'Lock Type' },
          { fieldName: "purpose_id", displayName: 'Purpose' },
          { fieldName: "location", displayName: 'Location' }
        ]);
        if (_.isNaN(accessId)) { //information for create access
          setIsCreation(true);
          setAccess({
            attributes: {
              unit_id: info.unitId,
              lock_type_id: null,
              lock_maker_id: null,
              lock_model_id: null,
              location: null,
              access_name: null,
              purpose_id: null,
              access_sequence: null,
              internal_notes: null,
              prefix_instructions: null,
              guest_instructions_notes: null,
              validated: true,
              created_by: info.user,
              configurations: configurations
            },
            type: "unit_access"
          });
          chargeModelsByDefault(allModelsResponse.data);
        } else { //get information for Edit access
          setIsCreation(false);
          const unitAccessResponse: AccessRetrieveResponse = await unitService.getAccessesById(accessId,undefined,info.user);
          //add configurations as part of the access
          const unitAccessConfig: Array<UnitAccessConfiguration> = unitAccessResponse.data.attributes.configurations ?? [];

          //set Access and configurations
          setAccess(unitAccessResponse.data);
          setConfigurations(unitAccessConfig);

          // set make and model
          if (!_.isNil(unitAccessResponse.included)){
            const includedLockModel: LockModel = unitAccessResponse.included.find((item) => {
              if (item.type === "lock_model")
                return item.attributes;
            })

            if (!_.isNil(includedLockModel))
              setCurrentModel({id: +includedLockModel.id, label: includedLockModel.attributes.display_name});

            await getMakesByLockType(+unitAccessResponse.data.attributes.lock_type_id, +includedLockModel?.attributes?.lock_maker_id);
          } else {
            await getMakesByLockType(+unitAccessResponse.data.attributes.lock_type_id);
          }

          // set sequences by purpose
          await getSequences(+unitAccessResponse.data.attributes.purpose_id);
        }
        const unitMainAccess = await unitService.getUnitMainAccess(info.unitId);
        if (unitMainAccess.data.length > 0) setMainAccess(unitMainAccess.data[0]);
      } catch (e) {
        console.error(e);
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const addRequiredFields = (requiredConfigToAdd: { fieldName: string, displayName: string, typeId: number }) => {
    if (!requiredConfigs.find(r => r.typeId === requiredConfigToAdd.typeId)) {
      const clone = requiredConfigs;
      clone.push(requiredConfigToAdd);
      setRequiredConfigs(clone);
    }
  }

  const save = async(validate?: boolean) => {
    setIsLoading(true);
    let successMessage = "Saved Access";
    let errorMessage = "Error saving Access";
    //Error when is a new access
    if (isCreation) {
      access.attributes['configurations'] = configurations;
      try {
        const toCreate = setAuditFields(clearRequest());
        if (validateRequiredFields(toCreate)) {
          await unitService.createUnitAccesses(toCreate);
          addToast(successMessage, {appearance: "success"});
          handleCloseModal();
          reloadSummary(true);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        const messageDetail = e.response?.data[0]?.detail?.error ?? e;
        addToast(`${errorMessage} ${messageDetail}`, { appearance: "error" });
      }
    } else {
      try {
        const toUpdate = setAuditFields(clearRequest());
        toUpdate.attributes['configurations'] = configurations;

        //adding validate field
        if (validate === true) {
          toUpdate.attributes.validated = true;
          successMessage = "Validated and saved Access";
          errorMessage = "Error validating and saving Access";
        }

        if (validateRequiredFields(toUpdate)) {
          const configsToSend = [];
          toUpdate.attributes.configurations.map(c => {
            if (!protectedConfigValues[c.configuration_type.config_type_name]) {
              configsToSend.push(c);
            }
          });
          toUpdate.attributes.configurations = configsToSend;

          await unitService.updateUnitAccesses(+toUpdate.id, toUpdate)
          addToast(successMessage, {appearance: "success"});
          handleCloseModal();
          reloadSummary(true);
        } else {
          setIsLoading(false);
        }

      } catch (e) {
        setIsLoading(false);
        console.error(e);
        const messageDetail = e.response?.data[0]?.detail?.error ?? e;
        addToast(`${errorMessage}. ${messageDetail}`, { appearance: "error" });
      }
    }
  }

  const deleteAccess = async () => {
    try {
      const accessId = adminManager.getId();
      const deleteResponse = await unitService.deleteUnitAccess(accessId, info.user);
      setModalVisible(false);
      addToast("Access deleted succefully", { appearance: "success" });
      handleCloseModal();
      reloadSummary();
    } catch (e) {
      addToast(`Error deleting access ${e}`, { appearance: "error" });
      setModalVisible(false);
    }
  }

  const clearRequest = () => {
    const request = _.cloneDeep(access);
    delete request.attributes['created_at'];
    delete request.attributes['modified_at'];
    delete request.attributes['created_by'];
    delete request.attributes['modified_by'];
    delete request.attributes['instructions'];
    delete request.attributes['lock_type'];

    if (!isCreation) delete request.attributes['unit_id'];

    for (const attr in request.attributes) {
      const value = request.attributes[attr];
      if (typeof value === "string"){
        const trimmedValue = value.toString().trim();
        request.attributes[attr] = trimmedValue === '' ? null : trimmedValue;
      }
    }

    configurations?.map((c) => {
      delete c['created_at'];
      delete c['modified_at'];
      delete c['created_by'];
      delete c['modified_by'];

      for (const attr in c) {
        const value = c[attr];
        if (typeof value === "string"){
          const trimmedValue = value.toString().trim();
          c[attr] = trimmedValue === '' ? null : trimmedValue;
        }
      }
    });
    return request;
  }

  const setAuditFields = (request: Accesses) => {
    if (isCreation) request.attributes['created_by'] = info.user;
    else request.attributes['modified_by'] = info.user;

    configurations?.map((c) => {
      if (_.isNil(request.id)) c['created_by'] = info.user;
      else c['modified_by'] = info.user;
    });

    return request;
  }

  const validateRequiredFields = (request: Accesses): boolean => {
    let validFields = true;

    //check access fields
    requiredFields.map (field => {
      if (_.isNil(request.attributes[field.fieldName])) {
        validFields = false;
        addToast(`${field.displayName} cannot be empty`, { appearance: "error" });
      }
    });

    //check access configs
    requiredConfigs.map (field => {
      const accessConfig = request?.attributes?.configurations?.find(ac => ac.configuration_type_id === field.typeId);

      if (!protectedConfigValues[accessConfig?.configuration_type.config_type_name]) {
        if (_.isNil(accessConfig) || _.isNil(accessConfig.value)) {
          validFields = false;
          addToast(`${field.displayName} cannot be empty`, { appearance: "error" });
        }
      }
    });

    //custom require validations
    // make required when model is set
    if (!_.isNil(request.attributes.lock_model_id) && _.isNil(request.attributes.lock_maker_id)) {
      validFields = false;
      addToast(`Make cannot be empty when Model is added`, { appearance: "error" });
    }

    return validFields;
  }

  // Charge page information methods
  const getSelectValues = async () => {
    const promises = []
    promises.push(getLockTypes());
    promises.push(getPurposes());
    promises.push(getAccessTypes());
    await Promise.all(promises);
  }

  const getAccessTypes = async () => {
    const accessTypeResponse: AccessTypes = await unitService.getAccessTypeById(accessTypeId);
    if (!_.isNil(accessTypeResponse)) {
      const accessTypeList = [];
      accessTypeList.push({
        value: accessTypeResponse.id,
        display: accessTypeResponse.attributes.display_name
      })
      setAccessTypes(accessTypeList);
    }
  }

  const getLockTypes = async () => {
    const lockTypesResponse: Array<LockTypes> = await unitService.getLockTypeByAccessType(accessTypeId);
    if (!_.isNil(lockTypesResponse)) {
      const lockTypeList = [];
      for (const lockType of lockTypesResponse) {
        lockTypeList.push({
          value: lockType.id,
          display: lockType.attributes.display_name
        })
      }
      setLockTypes(lockTypeList);
    }
  }

  const getPurposes = async () => {
    const purposeResponse: Array<AccessPurposes> = await unitService.getPurposesByAccessType(accessTypeId);
    if (!_.isNil(purposeResponse)) {
      const purposeList = [];
      for (const purpose of purposeResponse) {
        purposeList.push({
          value: purpose.id,
          display: purpose.attributes.display_name
        })
      }
      setPurposes(purposeList);
    }
  }

  const getSequences = async (purpose: number) => {
    if (_.isNil(purpose)) return;

    const accessResponse: AccessListResponse = await unitService.getUnitAccessesByPurpose(info.unitId, purpose);
    if (!_.isNil(accessResponse?.data)) {
      const sorted = accessResponse.data.sort((a,b) => {return a.attributes?.access_sequence - b.attributes?.access_sequence});
      const sequenceList = [];
      for (const access of sorted) {
        sequenceList.push({
          value: +access.attributes?.access_sequence,
          display: access.attributes?.access_sequence
        })
      }
      // when is creation form, we add one more sequence at the end
      if (isCreation) {
        sequenceList.push({
          value: sequenceList.length + 1,
          display: sequenceList.length + 1
        })
      }

      // when empty it means there is no access for that purpose so we add 1
      if (sequenceList.length === 0) {
        sequenceList.push({
          value: 1,
          display: "1"
        });
      }

      setSequences(sequenceList);
      if (isCreation) { // if isCreation form we set the sequence value to the first available
        setValue("access_sequence", sequenceList[sequenceList.length -1].value);
        setSelectState({
          ...selectState,
          sequence: sequenceList[sequenceList.length -1].value
        });
      }
    }
  }

  /**
   * set the list of Makes by access Lock Type
   * if accessModelMake is not null, current Make is assign
   * @param lockType
   * @param accessModelMake
   */
  const getMakesByLockType = async (lockType: number, accessModelMake: string | null = null) => {
    if (_.isNil(lockType)) return;

    const makesResponse: MakeListResponse = await unitService.getMakesByLockType(lockType);

    if (!_.isNil(makesResponse?.data)) {
      const makesList = [];
      for (const make of makesResponse.data) {
        makesList.push({
          id: +make.id,
          label: make.attributes?.display_name
        })
      }
      //setAllMakes(makesResponse.data)
      setMakes(makesList);

      if (!_.isNil(accessModelMake)) {
        setCurrentMake(makesList.find(make => make.id === accessModelMake))
      }
    }
  }

  const getModelsByLockTypeAndMakes = async (lockType: number, lockMake: number) => {
    if (_.isNil(lockType) || _.isNil(lockMake)) return;
    const modelResponse: ModelListResponse = await unitService.getModelsByLockTypeAndMakeId(lockType, lockMake);

    if (!_.isNil(modelResponse?.data)) {
      const modelList = [];
      for (const model of modelResponse.data) {
        modelList.push({
          id: +model.id,
          label: model.attributes?.display_name
        })
      }
      setModels(modelList);
    }
  }
  // End Charge page information methods

  // Handle component changes
  const setValue = (name: string, value: string | number | boolean)  => {
    if (access.attributes.hasOwnProperty(name)) {
      access.attributes[name] = value;
      setAccess(access);
    }
  }

  /**
   Set value for input components
   */
  const setInputValue = (name:string, event: any)  => {
    setValue(name, event.target.value);
    setInputState({...inputState, [name]: event.target.value});
  }

  /**
   Set value for selects components
   */
  const setChosenValue = async (name: string, selectedValue: any) => {
    if (name === "sequence") setValue("access_" + name, selectedValue.target.value)
    else setValue(name + "_id", selectedValue.target.value)
    setSelectState({...selectState, [name]: selectedValue.target.value});

    switch (name) {
      case "purpose": {
        // sequence options reset
        await getSequences(selectedValue.target.value);
        break;
      }
      case "lock_type": {
        await getMakesByLockType(accessTypeId);
        break;
      }
      case "sequence": {
        // if is unit entrance purpose , not is the main access and selected sequences is lower that main access sequence
        // warning alert is triggered
        if (mainAccess.id !== access.id && (access.attributes.purpose_id === 1 && selectedValue.target.value <= mainAccess?.attributes?.access_sequence) ){
          addToast("After saving, the current access will become the main access for the unit", {appearance: "warning"})
        }

        if (mainAccess.id === access.id && selectedValue.target.value > mainAccess?.attributes?.access_sequence) {
          addToast("This access is currently the unit's Main access, so increasing the sequence could reassign the unit's Main access.", {appearance: "warning"});
        }
      }
    }
  }
  /**
   * charge all models because make is not defined or unknown
   */
  const chargeModelsByDefault = (currentModelList?: any) => {
    const modelsToUse = _.isNil(currentModelList) ? allModels : currentModelList;
    const modelList = []
    for (const model of modelsToUse) {
      modelList.push({id: model.id, label: model.attributes.display_name});
    }
    setModels(modelList);
  }


  /**
   Set value for autocomplete when is set by input text
   */
  const onChangeAutocompleteInput = async (name: string, value: string) => {
    const trimmedValue = value.trim();
    switch (name) {
      case "lock_maker": {
        const existingMake = makes.find(make => make.label.toLowerCase() === trimmedValue.toLowerCase());
        if (existingMake) {
          //only search related models when Make exists.
          await getModelsByLockTypeAndMakes(accessTypeId, existingMake?.id); // get models of the selected make
        } else {
          //show all models
          chargeModelsByDefault();
        }
        const newValue = existingMake ? existingMake.id : trimmedValue;
        createOrSetAccessField(name + "_id", newValue)
        break;
      }
      case "lock_model": {
        const existingModel = models.find(model => model.label.toLowerCase() === trimmedValue.toLowerCase());
        const newValue = existingModel ? existingModel.id : trimmedValue;
        setValue(name + "_id", newValue);
        //find model in AllModel list to suggest make if it is empty
        if (_.isNil(access.attributes.lock_maker_id) && _.isNil(currentMake)) {
          const existingAllModels = allModels.find(model => model.attributes.display_name.toLowerCase() === trimmedValue.toLowerCase() && model.attributes.lock_type_id === accessTypeId);
          if (existingAllModels) {
            const proposedMake = makes.find(make => make.id === existingAllModels.attributes.lock_maker_id);
            if (currentMake?.id !== proposedMake?.id)
              setCurrentMake(proposedMake);
          }
        }
        break;
      }
    }
    if (_.isNil(access.attributes.lock_maker_id)) {
      setNotSpecifiedMakeSuggestion(true);
    } else {
      setNotSpecifiedMakeSuggestion(false);
    }
  }

  /**
   Set value for switch components
   */

  const handleCloseModal = () => {
    setIsLoading(true);
    window.parent.postMessage({action: 'click', type: 'closeAccessModal'}, '*');
  };

  const reloadSummary = (success?: boolean) => {
    window.parent.postMessage({action: "click", type: "reloadSummary", success}, "*");
  };

  const createOrSetAccessField = (name: string,  newValue: any) => {
    newValue = newValue === "" ? null : newValue;
    if (access.attributes.hasOwnProperty(name )) {
      setValue(name, newValue);
    } else {
      access.attributes[name] = newValue;
      setAccess(access);
    }
  }
  // End Handle component changes

  // component functions
  const left = {
    variant: "info" as any,
    onClick: handleCloseModal,
    children: (
      <React.Fragment>
        Cancel
      </React.Fragment>
    ),
    disabled: isLoading,
  };

  const right = {
    variant: "secondary" as any,
    onClick: save,
    children: "Save",
    disabled: isLoading,
  };

  const showConfigurationComponent = (access: Accesses) => {
    switch (access.attributes?.lock_type_id) {
      case 1: {
        return (
          <LockConfigurationsComponent
            accessConfigurations={configurations}
            accessId={+access.attributes.id}
            lockTypeId={access.attributes?.lock_type_id}
            setAccessConfigurations={setConfigurations}
            addRequiredFields={addRequiredFields}
            protectedConfigValues={protectedConfigValues}
            setProtectedConfigValues={setProtectedConfigValues}
          />
        )
        break;
      }
      default: {
        return (<React.Fragment/>)
        break;
      }
    }
  }

  /**
   * Replace instructions value with mask or unmask codes
   */
  const handleInstructions = async () => {
    try {
      const tempProtected = _.cloneDeep(protectedConfigValues);
      tempProtected['instructions'] = !protectedConfigValues['instructions'];
      const unmaskedInstructions = await unitService.getAccessesById(access.id, !protectedConfigValues['instructions'], info.user);
      const tempAccess = _.cloneDeep(access);
      tempAccess.attributes.instructions = unmaskedInstructions.data.attributes.instructions;
      setAccess(tempAccess);
      setProtectedConfigValues(tempProtected);
    } catch (e) {
      console.error(e);
      addToast( e.message ?? `Error loading Unit Access information`, { appearance: "error" });
    }
  };

  const handleShowNotes = (noteId: string) => {
    const notes = document.getElementById(noteId);
    notes?.classList.remove(styles.hide);
    const addLink = document.getElementById("add_"+noteId);
    addLink?.classList.add(styles.hide);
  };

  const required = (<ThemeProvider theme={_default}><Required type={"error"} size={"small"} text={"*"}></Required></ThemeProvider>);
  const guestInstructionsText = (<ThemeProvider theme={_default}><Required type={"info"} size={"small"} text={"This will be added to the instructions"}></Required></ThemeProvider>);
  const internalNotesText = (<ThemeProvider theme={_default}><Required type={"info"} size={"small"} text={"Only visible for Vacasa users"}></Required></ThemeProvider>);
  // End component functions

  const renderModal = (): JSX.Element => {
    return (<Modal
      open={modalVisible}
      onClose={setModalVisible}
    >
      <ThemeProvider theme={_default}>
        <div className={`${AccessDetailStyles.paper} ${AccessDetailStyles.modalStyle}`}>
          <h2 id="simple-modal-title">Delete</h2>
          <p id="simple-modal-description">
            Are you sure you want to delete this access?
          </p>
          <div className="row">
            <div className={`col-md-3 offset-md-2 ${styles.modalButton}`}>
              <Button variant="secondary" onClick={() => deleteAccess()}>
                Confirm
              </Button>
            </div>
            <div className={`col-md-3 offset-md-1 ${styles.modalButton}`}>
              <Button variant="info" onClick={() => setModalVisible(false)}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Modal >);
  }

  return (
    <React.Fragment>
      <div className={"container-modal"}>
        <div className={styles.titleShelf}>
          <h6 className={"left"}><b>{isCreation ? "Add Access" : "Edit Access"}</b></h6>
        </div>
        {isLoading ? (
          <div className="row">
            <div className={`col centerContent}`}>
              <h6>Loading data...</h6>
            </div>
          </div>
        ) : (
          <div className={styles.accessDetailsContainer}>
            <form>
              <div className={`form-row ${styles.customFormRow}`}>
                <div className={`col-sm-6 ${styles.customCol}`}>
                  <div className={`row ${styles.customRow} ${styles.extraInstructionsDiv_prefix}`}>
                    <b><a id="add_prefix_instructions" href={"#"} onClick={() => handleShowNotes("prefix_instructions")} className={`${styles.addNotesText} ${styles.addInstructionsText} ${access.attributes?.prefix_instructions !== null ? styles.hide : ''}`}>+ Add Instructions for before unlocking</a></b>
                  </div>
                  <div id="prefix_instructions" className={`${styles.textareaRow_prefix} ${access.attributes?.prefix_instructions === null ? styles.hide : ''}`}>
                    <TextareaComponent
                      selectedItem={access}
                      text={`${access.attributes?.prefix_instructions ?? ""}`}
                      id={`prefix_instructions_${access.id}`}
                      name={"prefix_instructions"}
                      placeholder={"Add Instructions"}
                      maxLength={1000}
                      readOnly={false}
                      labelName= {"Instructions before unlocking"}
                      disabled={false}
                      setItem={setAccess}
                    />
                  </div>
                  <div className={`row ${styles.customRow}`}>
                    <div className={"col-sm-12"}>
                      <label>
                        <strong>Step Instruction Preview: </strong>
                        <ThemeProvider theme={_default}>
                          <Required type={"info"} size={"small"} text={"This field is generated after saving"}></Required>
                        </ThemeProvider>
                      </label>
                      <button type="button" className={`${styles.icon_left} ${styles.notButton}`} onClick={() => handleInstructions()}>
                        {!isCreation ? (
                          <ThemeProvider theme={_default}>
                            {protectedConfigValues['instructions'] ? (
                              <Tooltip message={"Reveal instruction codes"} placement={"bottom"}>
                            <span>
                              <Icon.EyeOff className="pointer" name="info" width={18} height={18} />
                            </span>
                              </Tooltip>
                            ) : (
                              <Tooltip message={"Mask instruction codes"} placement={"bottom"}>
                            <span>
                              <Icon.Eye className="pointer" name="info" width={18} height={18} />
                            </span>
                              </Tooltip>
                            )}
                          </ThemeProvider>
                        ) : (
                          <React.Fragment />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-12">
                      {_.isNil((access.attributes?.instructions)) ? (
                        <p className={`${styles.box_paragraph} ${styles.box_text}`}>
                          *There are no instructions configured yet.
                        </p>
                      ) : (
                        <p className={`${styles.box_paragraph} ${styles.box_text}`}>
                          {access.attributes?.instructions?.substr(0, maxLength)}
                          {access.attributes?.instructions?.length > maxLength && !isCollapsed && <span className={styles.box_text}>{access.attributes?.instructions?.substr(maxLength, access.attributes?.instructions?.length)}</span>}
                          {access.attributes?.instructions?.length > maxLength && (
                          <span className={styles.box_toggle} onClick={() => setCollapsed(!isCollapsed)}>
                            { isCollapsed ? openText : collapseText}
                          </span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`row ${styles.customRow} ${styles.extraInstructionsDiv_suffix}`}>
                    <b><a id="add_suffix_instructions" href={"#"} onClick={() => handleShowNotes("suffix_instructions")} className={`${styles.addNotesText} ${styles.addInstructionsText} ${access.attributes?.guest_instructions_notes !== null ? styles.hide : ''}`}>+ Add Instructions for after unlocking</a></b>
                  </div>
                  <div id="suffix_instructions" className={`${styles.textareaRow_suffix} ${access.attributes?.guest_instructions_notes === null ? styles.hide : ''}`}>
                    <TextareaComponent
                      selectedItem={access}
                      text={`${access.attributes?.guest_instructions_notes ?? ""}`}
                      id={`additional_notes_${access.id}`}
                      name={"guest_instructions_notes"}
                      placeholder={"Add Notes"}
                      maxLength={1000}
                      readOnly={false}
                      labelName= {"Instructions after unlocking"}
                      disabled={false}
                      required={guestInstructionsText}
                      setItem={setAccess}
                    />
                  </div>
                </div>

                <div className={`col-sm-6 ${styles.customCol}`}>
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-4">
                      <label><strong>Name</strong></label>
                    </div>
                    <div className="col-sm-8">
                      <ThemeProvider theme={_default}>
                        <Input
                          type={"text"}
                          value={`${access.attributes?.access_name ?? ""}`}
                          onChange={(newValue)=> setInputValue("access_name", newValue)}
                          customClass={`${styles.inputLeft}`}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-4">
                      <label><strong>Lock Type</strong>{required}</label>
                    </div>
                    <div className="col-sm-8">
                      <ThemeProvider theme={_default}>
                        <Select
                          value={`${access.attributes?.lock_type_id}`}
                          onChange={(newValue)=> setChosenValue("lock_type", newValue)}
                          disabled={isCreation ? false : true}
                          options={lockTypes}
                          customClass={styles.selectLeft}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-4">
                      <label>
                        <strong>
                          Make
                          {notSpecifiedMakeSuggestion ? (
                            <ThemeProvider theme={_default}>
                              <Tooltip message={"If not known, use Not specified"} placement={"bottom"}>
                              <span>
                                <Icon.AlertTriangle className={`pointer ${styles.icon_make}`} name="info" width={16} height={16} ></Icon.AlertTriangle>
                              </span>
                              </Tooltip>
                            </ThemeProvider>
                          ) : (
                            <React.Fragment/>
                          )}
                          / Model
                        </strong>
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <Autocomplete
                        id="make-box"
                        freeSolo
                        options={makes}
                        getOptionLabel={(option) => (option ? option.label : "")}
                        value={currentMake}
                        disabled={access.attributes?.lock_type_id === null ? true : false}
                        renderInput={(params) => (
                          <TextField {...params} label="" margin="normal" variant="outlined" className={`${styles.customAutocomplete}`} placeholder={"If not known, use Not specified"}/>
                        )}
                        onInputChange={(event, newValue)=> onChangeAutocompleteInput("lock_maker", newValue)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Autocomplete
                        id="model-box"
                        freeSolo
                        options={models}
                        getOptionLabel={(option) => (option ? option.label : "")}
                        value={currentModel}
                        disabled={access.attributes?.lock_type_id === null ? true : false}
                        renderInput={(params) => (
                          <TextField {...params} label="" margin="normal" variant="outlined"  className={`${styles.customAutocomplete}`} placeholder={"If not known, use Not specified"}/>
                        )}
                        onInputChange={(event, newValue)=> onChangeAutocompleteInput("lock_model", newValue)}
                      />
                    </div>
                  </div>
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-4">
                      <label><strong>Location</strong>{required}</label>
                    </div>
                    <div className="col-sm-8">
                      <ThemeProvider theme={_default}>
                        <Input
                          type={"text"}
                          value={`${access.attributes?.location ?? ""}`}
                          onChange={(newValue)=> setInputValue("location", newValue)}
                          customClass={`${styles.inputLeft}`}
                          placeholder={"Add Location"}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  {showConfigurationComponent(access)}
                  <div className={`row ${styles.customRow}`}>
                    <div className="col-sm-4">
                      <label><strong>Purpose{required} / Order</strong></label>
                    </div>
                    <div className="col-sm-6">
                      <ThemeProvider theme={_default}>
                        <Select
                          value={`${access.attributes?.purpose_id}`}
                          onChange={(newValue)=> setChosenValue("purpose", newValue)}
                          disabled={isCreation ? false : true}
                          options={purposes}
                          customClass={styles.selectLeft}
                        />
                      </ThemeProvider>
                    </div>
                    <div className="col-sm-2">
                      <ThemeProvider theme={_default}>
                        <Select
                          value={access.attributes?.access_sequence}
                          onChange={(newValue)=> setChosenValue("sequence", newValue)}
                          disabled={false}
                          options={sequences}
                          customClass={styles.selectLeft}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <div id="internal_notes" className={`${styles.textareaRow} ${access.attributes?.internal_notes === null ? styles.hide : ''}`}>
                    <TextareaComponent
                      selectedItem={access}
                      text={`${access.attributes?.internal_notes ?? ""}`}
                      id={`internal_notes_${access.id}`}
                      name={"internal_notes"}
                      placeholder={"Add Notes"}
                      maxLength={1000}
                      readOnly={false}
                      labelName= {"Vacasa Internal Notes"}
                      disabled={false}
                      required={internalNotesText}
                      setItem={setAccess}
                    />
                  </div>

                  <div className={`row ${styles.customRow} ${styles.internalNotesDiv}`}>
                    <b><a id="add_internal_notes" href={"#"} onClick={() => handleShowNotes("internal_notes")} className={`${styles.addNotesText} ${styles.addInternalNotesText} ${access.attributes?.internal_notes !== null ? styles.hide : ''}`}>+ Add Vacasa Internal notes</a></b>
                  </div>
                </div>
            </div>
          </form>
        </div>
      )}

        <div className={`content-footer ${styles.footerShelf}`}>
          <ThemeProvider theme={_default}>
            <div className={`${styles.validate}`}>
            {!isCreation && access.attributes?.validated && !isLoading ? <Tooltip title="This button is disable if the access is visible to the guest" aria-label="">
                <span>
                  <ThemeProvider theme={_default}>
                    <Button variant={"danger"} customClass={''} onClick={() => setModalVisible(true)}>Delete</Button>
                  </ThemeProvider>
                </span>
              </Tooltip> : <React.Fragment />}
              {!isCreation && (!access.attributes?.validated && !isLoading) ? (
                <React.Fragment >
                  <Button variant={"danger"} customClass={''} onClick={() => save(true)}>Validate & Save</Button>
                  <div className={styles.validate_message}>
                    <AlertMessage type={"error"} height={"small"} customClass={styles.customAlert}>
                      This access is not yet validated. Validate and save the information with the button on the left
                    </AlertMessage>
                  </div>
                </React.Fragment >
              ) :
                (<React.Fragment />)
              }
            </div>

            <div className={`${styles.button}`}>
              <ButtonGroup left={left} right={right}></ButtonGroup>
            </div>
          </ThemeProvider>
        </div>


      </div>
      {renderModal()}
    </React.Fragment>
  )
}