import style from "./main.module.scss";
import React from "react";

interface MainProps {
  position: 'floating' | 'fixed';
}

export const MainComponent: React.FunctionComponent<MainProps> = (props) => {
  const { position } = props;

  return (
    <React.Fragment>
      <div className={`${style.flag} ${position === "fixed" ? style.flag_fixed : style.flag_floating}`}/>
    </React.Fragment>
  )
}