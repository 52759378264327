import styles from "./NotesComponent.module.scss";
import React, { useEffect, useState } from "react";
import { UnitAccess, UnitAccessNotesResponse } from "@common/typing";
import { TextareaComponent } from "@common/react-components";
import { Button, Required, ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import { useToasts } from "react-toast-notifications";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import * as _ from "lodash";

interface NotesProps { }

export const NotesComponent: React.FunctionComponent<NotesProps> = (props) => {
  const [originalAdditionalNotes, setOriginalAdditionalNotes] = useState<string | null>(null);
  const [originalInternalNotes, setOriginalInternalNotes] = useState<string | null>(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const [unitAccessNotes, setUnitAccessNotes] = useState<UnitAccess>({
    type: "unit_access_units",
    attributes: {
      unit_id: null,
      additional_notes: null,
      internal_notes: null,
      unit_entrance_instructions: null
    }
  });

  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();

  const { addToast } = useToasts();

  useEffect(() => {
    (async () => {
      try {
        await loadUnitAccessNotes();
        handleButton();
      } catch (e) {
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      handleButton();
    })();
  }, [unitAccessNotes]);

  const loadUnitAccessNotes = async () => {
    try {
      const unitAccessNotesResponse: UnitAccessNotesResponse = await unitService.getUnitAccessNotes(info.unitId); //1111111111
      setUnitAccessNotes(unitAccessNotesResponse.data);
      setOriginalAdditionalNotes(unitAccessNotesResponse.data?.attributes?.additional_notes);
      setOriginalInternalNotes(unitAccessNotesResponse.data?.attributes?.internal_notes);
    } catch (e) {
      if (e.response.status !== 404) {
        addToast(`Error loading Access Notes information`, { appearance: "error" });
      }
    }
  }

  const handleButton = () => {
    if (_.isNil(unitAccessNotes.attributes.unit_id)) {
      setDisabledButton(true);
      return;
    }
    if (unitAccessNotes.attributes.additional_notes !== originalAdditionalNotes) {
      setDisabledButton(false);
      return;
    }

    if (unitAccessNotes.attributes.internal_notes !== originalInternalNotes) {
      setDisabledButton(false);
      return;
    }

    if (unitAccessNotes.attributes.internal_notes === originalInternalNotes && unitAccessNotes.attributes.additional_notes === originalAdditionalNotes) {
      setDisabledButton(true);
      return;
    }
    setDisabledButton(false);
  }

  const saveNotes = async () => {
    setDisabledButton(true);
    let successMessage = "Saved Access Notes";
    let errorMessage = "Error saving Access Notes";

    try {
      if (unitAccessNotes.attributes.unit_id === null) addToast(`Create access notes is not enabled yet`, { appearance: "error" });
      else {
        const toUpdate = clearRequest();
        toUpdate.attributes['modified_by'] = info.user;

        await unitService.updateUnitAccessNotes(+toUpdate.attributes.unit_id, toUpdate)
        addToast(successMessage, { appearance: "success" });
      }
    } catch (e) {
      console.error(e);
      addToast(`${errorMessage} ${e}`, { appearance: "error" });
    } finally {
      handleButton();
    }
  }

  const clearRequest = () => {
    const request = _.cloneDeep(unitAccessNotes);
    delete request.attributes['created_at'];
    delete request.attributes['modified_at'];
    delete request.attributes['created_by'];
    delete request.attributes['modified_by'];
    delete request.attributes['uuid'];

    for (const attr in request.attributes) {
      const value = request.attributes[attr];
      if (typeof value === "string") {
        const trimmedValue = value.toString().trim();
        request.attributes[attr] = trimmedValue === '' ? null : trimmedValue;
      }
    }

    return request;
  }

  return (
    <div id={"notes-container"} className={`${styles.NotesContainer}`}>
      <div className={`${styles.NotesTextArea} ${styles.half}`}>
        <TextareaComponent
          selectedItem={unitAccessNotes}
          text={unitAccessNotes.attributes?.additional_notes}
          id={`additional_notes`}
          name={"additional_notes"}
          placeholder={"Add guest notes"}
          maxLength={255}
          readOnly={false}
          labelName={"Additional guest notes:"}
          disabled={_.isNil(unitAccessNotes.attributes.unit_id) ? true : false}
          setItem={setUnitAccessNotes}
        />
        <TextareaComponent
          selectedItem={unitAccessNotes}
          text={unitAccessNotes.attributes?.internal_notes}
          id={`internal_notes`}
          name={"internal_notes"}
          placeholder={"Add Internal notes"}
          maxLength={255}
          readOnly={false}
          labelName={"Internal notes"}
          disabled={_.isNil(unitAccessNotes.attributes.unit_id) ? true : false}
          setItem={setUnitAccessNotes}
        />
        <ThemeProvider theme={_default}>
          {_.isNil(unitAccessNotes.attributes.unit_id) ? (
            <Required type={"default"} text={"Create notes is not available now for this unit"} size={"small"}></Required>
          ) : (
            <Button variant={"secondary"} onClick={saveNotes} disabled={disabledButton}>Save Note</Button>
          )}
        </ThemeProvider>
      </div>
      <div className={`${styles.NotesTextArea} ${styles.full}`}>

        <TextareaComponent
          selectedItem={unitAccessNotes}
          text={unitAccessNotes.attributes?.unit_entrance_instructions}
          id={`global_instructions`}
          name={"global_instructions"}
          placeholder={"Instrucction summary"}
          maxLength={5000}
          readOnly={true}
          labelName={"Instruction message summary:"}
          disabled={true}
          setItem={setUnitAccessNotes}
        />
      </div>

    </div>
  )
}